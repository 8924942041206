<template>
  <div class="main">
    <!-- -->
    <div class="content">
      <div class="title">开发应用信息</div>
      <div class="appKey">
        <div class="flex option">
          <div>appkey:{{ appKey }}</div>
          <div class="btns">
            <el-button type="primary" size="mini" @click="copy('appKey')"
              >复制</el-button
            >
          </div>
        </div>
        <div class="info">
          appkey是适界平台开发识别码，配合secret可调用适界平台的接口能力。
        </div>
      </div>
      <div class="secret">
        <div class="flex option">
          <div>secret:{{ hiddenSecret }}</div>
          <div class="btns">
            <el-button type="primary" size="mini" @click="copy('secret')"
              >复制</el-button
            >
            <el-button size="mini" @click="reset">重置</el-button>
          </div>
        </div>
        <div class="info">
          secret是校验开发者身份的密码，具有极高的安全性。切记勿把密码直接交给第三方开发者或直接存储在代码中。
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { resetSecret, getUserInfo } from "@/api/index";
export default {
  name: "OpenWebDevelopApp",

  data() {
    return {
      appKey: "3231342318920987",
      secret: "12345678111111111111111112345678",
      hiddenSecret: "",
      asterisk: "****************",
    };
  },

  mounted() {
    this.getInfo()
    // this.hiddenSecret =
    //   this.secret.slice(0, 8) + this.asterisk + this.secret.slice(24, 32);
  },

  methods: {
    // 查询用户信息获取appKey
    async getInfo() {
      let res = await getUserInfo();
      this.appKey = res.data.appKey;
      this.secret = res.data.appSecret;
      this.hiddenSecret =
      this.secret.slice(0, 8) + this.asterisk + this.secret.slice(24, 32);
      // console.log(res, "resss");
      // getUserInfo().then((res) => {
      //   if (res.code == 200) {
      //     this.appKey = res.data.appKey;
      //     this.secret = res.data.appSecret;
      //     // this.hiddenSecret =
      //     //   this.secret.slice(0, 8) + this.asterisk + this.secret.slice(24, 32);
      //     // console.log(res, "resss")
      //   }
      // });
    },
    copy(type) {
      let copyValue = "";
      if (type === "appKey") {
        copyValue = this.appKey;
      } else {
        copyValue = this.secret;
      }
      this.$message.success("复制成功");
      navigator.clipboard.writeText(copyValue);
    },
    reset() {
      resetSecret().then(res=>{
        console.log(res, "resss")
        if(res.code == 200){
          this.$message.success("密钥重置成功")
          this.getInfo()
        }
      })
    },
  },
};
</script>
  <style lang="scss" scoped>
.main {
  height: 100%;
  padding: 0 10px;
  // width: 100%;
  background-color: #ecf0f3;
  .content {
    // height: calc(100% - 20px);
    background-color: #fff;
    padding: 10px;
    .title {
      padding: 10px 0 20px;
      border-bottom: 1px solid #ebeef5;
    }
    .appKey,
    .secret {
      padding: 10px 0;
      font-size: 14px;

      .info {
        color: #999999;
        padding: 10px 0;
      }
      .option {
        width: 500px;
        justify-content: space-between;
        .btns {
          width: 150px;
          display: flex;
          justify-content: flex-start;
        }
        .key {
          padding: 20px 0;
        }
      }
    }
    .appKey {
    }
    .secret {
    }
  }
}
</style>