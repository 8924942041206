<template>
  <custom-dialog
    :show="show"
    title="删除设备"
    @toggleShow="handleClose"
    width="444"
  >
    <div class="flex form" slot="main">
        是否确认删除设备？
    </div>

    <div class="flex btns" slot="footer">
      <el-button
        type="primary"
        class="btn save"
        size="small"
        @click="submit"
      >
        <!-- {{ $t("button.submit") }} -->
        确定
      </el-button>
      <el-button
        type="success"
        class="btn cancel"
        size="small"
        @click="handleClose"
      >
        取消
      </el-button>
    </div>
  </custom-dialog>
</template>
  
  <script>
import CustomDialog from "../../../components/public/customDialog";
import { reomveCamera } from "@/api/index";
export default {
  name: "AddDevice",
  components: {
    CustomDialog,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rowInfo: {
      type: Object,
      default: ()=>{
        return {}
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    title() {
      if (this.rowInfo && this.rowInfo.id) {
        return this.$t("dialog.userList.edit_title") + "-" + this.rowInfo.name;
      } else {
        return this.$t("dialog.userList.add_title");
      }
    },
    lang() {
      return this.$store.getters.lang;
    },
  },
  watch: {
    show: {
      handler(newV, oldV) {
        if (newV) {
          // console.log(213);
        }
      },
    },
  },
  mounted() {},
  methods: {
    submit(){
      let param = {
        sn: this.rowInfo.sn
      }
      console.log(this.rowInfo, param, "PPPPP")
      reomveCamera(param).then(res=>{
        if(res.code == 200){
          this.$message.success("设备删除成功")
          this.handleClose()
          this.$emit("refush")
        }
      })
    },
    handleClose(done) {
    //   this.$refs["form"].resetFields();
    //   this.$emit("toggleShow", null);
    this.$emit("update:show", false)
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.btns {
  justify-content: center;

  .btn {
    width: 180px;
    height: 32px;
  }

  .save {
    background-color: #0077ff;
    border-color: #0077ff;
  }

  .cancel {
    border-color: #0077ff;
    background-color: #ffffff;
    color: #0077ff;
    margin-left: 30px;
  }
}

.form {
  // justify-content: space-between;
  justify-content: center;
}

::v-deep {
  .el-dialog__body {
    text-align: center;
  }
}
</style>