<template>
  <div class="main">
    <div class="content">
      <div class="title" @click="setTitle">
        <span>开发文档</span>
        <span style="color: #0077ff">{{ crumbs }}</span>
      </div>
      <div v-if="!componentId" style="margin-top: 10px;">
        <div v-for="menu in list" :key="menu.url" class="menuTitle">
          <div class="menu" @click="changeOpen(menu)">
            <span style="margin-right: 5px;font-weight: bold;">·</span>
            <span @click="menu.url ? setComponent(menu.url) : ''">{{
              menu.title
            }}</span>
            <!-- v-if="menu.children.length" -->

            <div
              v-if="menu.children.length"
              class="arrow el-icon-caret-top"
              :style="{
                transform: menu.isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
              }"
            ></div>
          </div>
          <div
            v-if="menu.children.length"
            class="list"
            :class="{ expanded: menu.isOpen, expander: !menu.isOpen }"
          >
            <div
              v-for="list in menu.children"
              :key="list.url"
              class="menu"
              style="font-size: 14px"
              @click="setComponent(list.url)"
            >
              <span>{{ list.id }}</span>
              <span>{{ list.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 暂时先不缓存组件 -->
      <!-- <keep-alive> -->
      <component :is="componentId"></component>
      <!-- </keep-alive> -->
    </div>
  </div>
</template>

<script>
import introduction from "./component/introduction.vue"; // 简介
import codeC from "./component/code.vue"; // 状态码
import tokenC from "./component/token.vue"; // 生成access Token
import infoSearch from "./component/infoSearch.vue"; // 摄像头信息查询
import videoStreaming from "./component/videoStreaming.vue"; // 摄获取像头视频流
export default {
  name: "OpenWebDocument",
  components: {
    introduction,
    codeC,
    tokenC,
    infoSearch,
    videoStreaming,
  },
  data() {
    return {
      // isOpen: true,
      componentId: "",
      crumbs: "",
      list: [
        {
          title: "简介",
          children: [],
          url: "introduction",
          isOpen: true,
        },
        {
          title: "相关接口说明",
          isOpen: true,
          children: [
            {
              id: "2.1",
              title: "生成access Token",
              url: "tokenC",
            },
            {
              id: "2.2",
              title: "摄像头信息查询",
              url: "infoSearch",
            },
            {
              id: "2.3",
              title: "获取摄像头视频流",
              url: "videoStreaming",
            },
          ],
        },
        {
          title: "状态码",
          children: [],
          isOpen: true,
          url: "codeC",
        },
      ],
    };
  },

  mounted() {},

  methods: {
    changeOpen(row) {
      // this.isOpen = !this.isOpen
      row.isOpen = !row.isOpen;
      console.log(row);
    },
    setTitle() {
      this.componentId = "";
      this.crumbs = "";
    },
    // 设置组件
    setComponent(id) {
      this.componentId = id;
      switch (this.componentId) {
        case "introduction":
          this.crumbs = "/简介";
          break;
        case "codeC":
          this.crumbs = "/状态码";
          break;
        case "tokenC":
          this.crumbs = "/生成accessToken";
          break;
        case "infoSearch":
          this.crumbs = "/摄像头信息查询";
          break;
        case "videoStreaming":
          this.crumbs = "/获取摄像头视频流";
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  height: 100%;
  padding: 0 10px;
  // width: 100%;
  background-color: #ecf0f3;
  .content {
    height: calc(100% - 20px);
    background-color: #fff;
    overflow: auto;
    // overflow-y: scroll;
    // overflow-x: hidden;
    padding: 10px;
    .title {
      font-size: 15px;
      padding: 8px 0 18px;
      border-bottom: 1px solid #ebeef5;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .menuTitle {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      .menu:hover {
        cursor: pointer;
        color: #0077ff;
      }
      .menu {
        margin: 0 0 10px 10px;
        .arrow {
          // max-height: 0;
          transition: 500ms max-height ease;
        }
      }
      .list {
        // max-height: 0px;
        overflow: hidden;
        // transform: translateX(20px);
        margin-left: 43px;
      }
      .expander {
        max-height: 0;
        transition: max-height 0.2s ease;
        overflow: hidden;
      }
      .expanded {
        max-height: 1000px; /* 假定的足够大的值 */
        transition: max-height 1s ease;
      }
    }
  }
}
</style>