<template>
  <div class="intro">
    <!-- -->
    <div class="title">简介</div>
    <div>
      <p>
        本开发文档旨在为第三方开发人员提供适界平台开放接口的使用指南。通过这份文档，您将能够了解接口功能、调用方式以及开发过程中的注意事项。
      </p>
      <p>
        文档中详细列出了平台提供的各个接口，包括接口名称、请求参数、返回结果以及示例代码等。在使用本开发文档时，建议您仔细阅读每个接口的说明和示例代码，确保正确理解和使用接口功能。如有任何疑问或建议，欢迎随时联系我们的技术支持团队。
      </p>
      <p>
        我们致力于为您提供优质的技术支持和文档服务，期待与您共同打造更加完善的适界平台生态系统。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "OpenWebIntroduction",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
.intro {
  padding: 0 10px;
  .title {
    margin: 18px 0;
    font-size: 24px;
    font-weight: bold;
  }
  p{
    padding: 9px 0;
    line-height: 30px;
    // text-indent: 2em;
  }
}
</style>