<template>
  <custom-dialog
    :show="show"
    title="视频预览"
    @toggleShow="handleClose"
    width="900"
  >
    <div class="flex" slot="main">
      <div id="player"></div>
    </div>

    <!-- <div class="flex btns" slot="footer"></div> -->
  </custom-dialog>
</template>
      
<script>
import { getAddress } from "@/api/index";
import CustomDialog from "../../../components/public/customDialog";
import Player from "xgplayer";
import FlvPlugin from "xgplayer-flv"; // flv
import HlsPlugin from "xgplayer-hls"; // m3u8
import "xgplayer/dist/index.min.css";
export default {
  name: "OpenWebPreview",
  components: {
    CustomDialog,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rowInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      player: null,
      url: "",
    };
  },
  computed: {
    lang() {
      return this.$store.getters.lang;
    },
  },
  watch: {
    show: {
      handler(newV, oldV) {
        if (newV) {
          //   console.log(213);
          this.$nextTick(() => {
            // console.log("创建")
          });
        } else {
          if (this.player) {
            this.player.destroy(); // 销毁播放器
            this.player = null;
            console.log("销毁");
          }
        }
      },
    },
    rowInfo: {
      handler(newV, oldV) {
        console.log(newV,this.type, "为什么呢");
        if (newV.sn && this.type == 'preview') {
          
          this.toGetAddress().then(()=>{
            this.initPlayer();
          })
          // this.$nextTick(() => {
          //   this.initPlayer();
          // });
        }
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    initPlayer() {
      // console.log(this.url, "urlll");
      this.player = new Player({
        id: "player",
        isLive: true,
        playsinline: true,
        playbackRate: [], // 不打开倍速
        // url: "https://sf1-cdn-tos.huoshanstatic.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-360p.flv",
        // url: "http://10.1.2.4:18889/live/livestream200T008392400089.m3u8", // 测试地址
        // url: "http://220.161.87.62:8800/hls/1/index.m3u8", // 本地测试广播地址 m3u8
        // url: "https://rtmp.allynav.cn/live/livestream1622120044.flv",
        url: this.url, 
        autoplayMuted: true, // autoplay会被阻塞block 一般只允许静音自动播放
        // autoplay: true,
        // height: "225px",
        width: "100%",
        fitVideoSize: "fixWidth",
        plugins: [FlvPlugin], // 播放flv
        // plugins: [HlsPlugin], // 播放m3u8
        // fullscreen: {
        //   //   useCssFullscreen: true, // 全屏按钮将会调用页面内全屏
        //   rotateFullscreen: true, // 全屏旋转90°
        // },
        // screenShot: {
        //   saveImg: false,
        //   quality: 0.1,
        //   type: "image/jpg",
        //   format: ".jpg",
        //   disable: true,
        // }, //显示截图按钮
        // fitVideoSize: "auto"
      });
      // once 只监听一次
      this.player.on("canplay", () => {
        // 视频正常播放
        console.log("canplay");
        // if(this.timer){
        //     clearInterval(this.tiemr)
        // }
        // this.initPlayer();
        // this.player.play();
      });
      this.player.on("ended", () => {
        console.log("ended");
        // this.initPlayer();
        this.player.play();
      });
      this.player.on("complete", () => {
        console.log("complete");
        this.player.play();
        // this.initPlayer();
      });
      this.player.on("error", () => {
        console.log("error");
      });
    },
    async toGetAddress() {
      let param = {
        sn: this.rowInfo.sn,
        channel: this.rowInfo.channel,
      };
      let formData = new FormData();
      formData.append("sn", this.rowInfo.sn);
      formData.append("channel", this.rowInfo.channel);
      let res = await getAddress(formData);
      // console.log(res, "直播地址")
      if (res.data) {
        this.url = res.data.addressList[0].address;
      }
    },
    handleClose(done) {
      this.$emit("update:show", false);
      this.$emit("update:rowInfo", {})
    },
  },
};
</script>
      
<style lang="scss" scoped>
.btns {
  justify-content: flex-end;

  .btn {
    width: 180px;
    height: 32px;
  }

  .save {
    background-color: #0077ff;
    border-color: #0077ff;
  }

  .cancel {
    border-color: #0077ff;
    background-color: #ffffff;
    color: #0077ff;
    margin-left: 30px;
  }
}

.form {
  // justify-content: space-between;
  justify-content: center;
  .content {
    width: 250px;
  }
  .address {
    text-align: left;
    border: 1px solid #eeeeee;
    padding: 10px;
  }
}

::v-deep {
  .el-dialog__body {
    text-align: center;
  }
}
</style>