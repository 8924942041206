<template>
  <div class="container">
    <div class="header_search">
      <div class="search">
        <el-input
          size="small"
          placeholder="设备SN/设备名称"
          v-model="searchKey"
        >
        </el-input>
        <!-- v-if="isAdmin" -->
        <el-input
          size="small"
          placeholder="请输入手机号筛选"
          v-model="phone"
          v-if="isAdmin"
        >
        </el-input>
      </div>
      <!-- <div class="addBtn">
        <el-button type="primary" @click="addDialog">新增</el-button>
      </div> -->
      <el-button size="small" type="primary" @click="search">搜索</el-button>
    </div>
    <div class="table_container">
      <!-- :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" -->
      <el-table
        ref="table"
        :data="tableData"
        height="100%"
        row-key="id"
        :header-cell-style="{ color: '#333333' }"
      >
        <el-table-column type="expand">
          <template slot-scope="{ row }">
            <div class="table" style="padding: 0 20px">
              <el-table
                class="insideTable"
                :data="row.list"
                style="width: 100%"
                :header-cell-style="{
                  backgroundColor: '#FFFFFF',
                  color: '#333333',
                }"
                align="center"
              >
                <el-table-column align="left" width="90"> </el-table-column>
                <el-table-column
                  align="left"
                  label="通道号"
                  prop="channel"
                  width="200"
                >
                </el-table-column>
                <el-table-column
                  align="left"
                  label="设备状态"
                  prop="status"
                  width="210"
                >
                  <template slot-scope="scope">
                    <span
                      :style="{
                        color: scope.row.status == 1 ? '#00EA37' : '#EA6F00',
                      }"
                    >
                      {{ scope.row.status == 1 ? "在线" : "离线" }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column align="left" label="操作">
                  <template slot-scope="{ row }">
                    <el-button type="text" @click="liveAddress(row, 'address')"
                      >直播地址</el-button
                    >
                    <el-button type="text" @click="preview(row, 'preview')"
                      >预览</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- {{ row }} -->
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="sn" label="设备SN" width="200">
        </el-table-column>
        <el-table-column
          align="center"
          prop="equip"
          label="设备名称"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="status"
          label="设备状态"
          width="200"
        >
          <template slot-scope="scope">
            <span
              :style="{ color: scope.row.status == 1 ? '#00EA37' : '#EA6F00' }"
            >
              {{ scope.row.status == 1 ? "在线" : "离线" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="phone" label="手机号" width="200">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <div>
              <el-button type="text" style="color: red" @click="del(scope.row)"
                >删除</el-button
              >
              <span style="margin: 0 5px">|</span>
              <el-button
                type="text"
                style="color: #0077ff"
                @click="setCamera(scope.row)"
                >设置</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <DelDevice
      :show.sync="show"
      :rowInfo.sync="rowInfo"
      @refush="getList"
    ></DelDevice>
    <Address :show.sync="showAddress" :rowInfo.sync="rowInfo" :type="opType"></Address>
    <Preview :show.sync="preShow" :rowInfo.sync="rowInfo" :type="opType"></Preview>
    <setCamera
      :show.sync="setShow"
      :rowInfo.sync="rowInfo"
      @refush="getList"
    ></setCamera>
  </div>
</template>

<script>
import DelDevice from "./components/delDevice.vue";
import Address from "./components/address.vue";
import Preview from "./components/preview.vue";
import setCamera from "./components/setCamera.vue";
import { getCameraList } from "@/api/index";

export default {
  name: "OpenWebHome1",
  components: {
    DelDevice,
    Address,
    Preview,
    setCamera,
  },
  data() {
    var checkSN = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入SN号"));
      } else {
        callback();
      }
    };
    var checkEquipName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入设备名称"));
      } else {
        callback();
      }
    };
    return {
      opType: "",
      isAdmin: false,
      filter: "",
      show: false,
      showAddress: false,
      preShow: false,
      setShow: false,
      rowInfo: {},
      page: 1,
      pageSize: 10,
      total: 0,
      searchKey: "",
      phone: "",
      showAdd: false,
      showDel: false,
      rules: {
        SN: [{ required: true, validator: checkSN, trigger: "blur" }],
        equipName: [
          { required: true, validator: checkEquipName, trigger: "blur" },
        ],
      },
      form: {
        sn: "",
        name: "",
      },
      tableData: [],
    };
  },
  computed: {
    // roleFlag() {
    //   return this.$store.state.permission.roleFlag;
    // },
  },
  mounted() {
    console.log(this.roleFlag, "flag");
    this.isAdmin =
      JSON.parse(localStorage.getItem("userInfo")).roleIds == 1 ? true : false;
    this.getList();
  },

  methods: {
    // 直播地址
    liveAddress(row, type) {
      this.showAddress = true;
      this.rowInfo = row
      this.opType = type
      // console.log(row, "直播地址");
    },
    // 预览
    preview(row, type) {
      this.preShow = true;
      this.rowInfo = row
      this.opType = type
      // console.log(row, "预览");
    },
    addDialog() {
      this.show = true;
    },
    handleSizeChange(e) {
      this.page = 1;
      this.pageSize = e
      this.getList();
    },
    handleCurrentChange(e) {
      this.page = e
      this.getList();
    },
    // 设置截图回传频率
    setCamera(row) {
      this.rowInfo = row
      this.setShow = true;
    },
    // 删除
    del(row) {
      this.show = true;
      this.rowInfo = row;
      console.log("删除", row);
    },
    getList() {
      let param = {
        keyword: this.searchKey,
        phoneNum: this.phone,
        limit: this.pageSize,
        page: this.page,
        // shareState:this,
      };
      getCameraList(param).then((res) => {
        this.total = res.data.total
        this.tableData = []
        console.log(res, "resss");
        this.tableData = res.data.list.map((item,index)=>{
          return {
            // index: index,
            id: item.sn,
            sn: item.sn,
            equip: item.name,
            status: item.online,
            phone: item.users ? item.users[0].phoneNum : "-",
            list: item.channel.split(",").map(it=>{
              return {
                channel: it,
                sn: item.sn,
                status: item.online
              }
            })
          }
        })
      });
      // console.log("获取列表数据");
    },
    search() {
      this.getList();
      console.log("搜索", this.searchKey);
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep{
//   .insideTable .el-table th.el-table__cell{
//     background-color: #fff !important;
//   }
// }
.container {
  width: 100%;
  height: 100%;
  background-color: #ecf0f3;
  padding: 10px;
  .header_search {
    display: flex;
    align-items: center;
    height: 72px;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    .search {
      // width: 40%;
      display: flex;
      align-items: center;
      // justify-content: center;
      .el-input {
        margin-right: 12px;
      }
    }
  }
}
.table_container {
  width: 100%;
  height: calc(100% - 72px - 10px - 40px);
  margin-top: 10px;
  // ::v-deep {
  //   .el-table__expanded-cell {
  //     padding: 0px;
  //     // padding: 0px 0px 0px 60px;
  //   }
  // }
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep {
}

.btn_w {
  min-width: 144px;
}
</style>