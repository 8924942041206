<template>
  <div class="container">
    <div class="title">摄像头信息查询</div>
    <div class="content main" v-for="(item, index) in info" :key="item.title">
      <template v-if="!item.tableData">
        <div class="listTitle">{{ item.title }}</div>
        <div :style="{ color: index == 1 ? '#0077FF' : '' }">
          {{ item.content }}
        </div>
      </template>
      <template v-else>
        <div class="listTitle">
          {{ item.title }}
        </div>
        <div class="table">
          <el-table
            :data="item.tableData"
            :header-cell-style="{
              backgroundColor: '#0077FF',
              color: '#FFF',
              fontWeight: '500',
            }"
          >
            <el-table-column
              v-for="(column, index) in item.tableKey"
              :key="column"
              :prop="column"
              :label="item.tableName[index]"
            >
            </el-table-column>
            <!-- <el-table-column prop="tips" label="提示"> </el-table-column> -->
          </el-table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "OpenWebInfoSearch",

  data() {
    return {
      info: [
        {
          title: "1、接口说明",
          content: "查询摄像头列表信息，包括设备名称、设备在线状态、设备通道。",
        },
        {
          title: "2、接口地址",
          content: "https://{域名}/api/v1/dev/queryCameraList",
        },
        {
          title: "3、认证方式",
          content: "token",
        },
        {
          title: "4、请求类型",
          content: "get",
        },
        {
          title: "5、请求参数",
          content: "",
          tableData: [
            {
              name: "sn",
              type: "string",
              require: "N",
              example: "",
              describe: "设备sn",
            },
            {
              name: "name",
              type: "string",
              require: "N",
              example: "",
              describe: "设备名称",
            },
            {
              name: "cardCode",
              type: "string",
              require: "N",
              example: "",
              describe: "设备卡号",
            },
            {
              name: "onlion",
              type: "string",
              require: "N",
              example: "",
              describe: "设备状态，-1离线 1在线",
            },
            {
              name: "limit",
              type: "int",
              require: "Y",
              example: "",
              describe: "页面大小",
            },
            {
              name: "page",
              type: "int",
              require: "Y",
              example: "",
              describe: "页码",
            },
          ],
          tableKey: ["name", "type", "require", "example", "describe"],
          tableName: ["名称", "类型", "是否必填", "示例值", "描述"],
        },
        {
          title: "6、返回参数",
          content: "",
          tableData: [
            {
              name: "code",
              type: "string",
              require: "Y",
              example: "200",
              describe: "返回状态码",
            },
            {
              name: "msg",
              type: "string",
              require: "Y",
              example: "成功",
              describe: "返回状态消息",
            },
            {
              name: "data",
              type: "object",
              require: "",
              example: "",
              describe: "",
            },
          ],
          tableKey: ["name", "type", "require", "example", "describe"],
          tableName: ["名称", "类型", "是否必填", "示例值", "描述"],
        },
        {
          title: "7、data",
          content: "",
          tableData: [
            {
              name: "limit",
              type: "int",
              require: "Y",
              example: "",
              describe: "页面大小",
            },
            {
              name: "page",
              type: "int",
              require: "Y",
              example: "",
              describe: "页码",
            },
            {
              name: "list",
              type: "array",
              require: "Y",
              example: "",
              describe: "设备列表",
            },
            {
              name: "total",
              type: "int",
              require: "Y",
              example: "",
              describe: "设备总数",
            },
          ],
          tableKey: ["name", "type", "require", "example", "describe"],
          tableName: ["名称", "类型", "是否必填", "示例值", "描述"],
        },
        {
          title: "8、list",
          content: "",
          tableData: [
            {
              name: "sn",
              type: "string",
              require: "N",
              example: "",
              describe: "摄像头sn",
            },
            {
              name: "name",
              type: "string",
              require: "N",
              example: "",
              describe: "设备名称",
            },
            {
              name: "cardCode",
              type: "string",
              require: "N",
              example: "",
              describe: "内置卡号",
            },
            {
              name: "channel",
              type: "string",
              require: "N",
              example: "",
              describe: "通道数",
            },
            {
              name: "freq",
              type: "int",
              require: "N",
              example: "",
              describe: "截图频率",
            },
          ],
          tableKey: ["name", "type", "require", "example", "describe"],
          tableName: ["名称", "类型", "是否必填", "示例值", "描述"],
        }
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
.title {
  padding: 10px 0;
  font-size: 24px;
  font-weight: 500;
}
.listTitle {
  font-weight: 500;
}
.main > div {
  margin: 20px 0;
  // background-color: red;
}
.table {
  width: 75%;
}
.main:last-child {
  padding-bottom: 100px;
}
</style>