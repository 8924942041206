<template>
  <div>
    <el-drawer
      :visible.sync="show"
      :direction="direction"
      :before-close="handleClose"
      :show-close="false"
    >
      <!-- 标题 -->
      <div slot="title" class="flex-hc">标题</div>
      <div class="main">
        <div class="form">
          <div class="item">
            <span>是否开启截图</span>
            <span
              ><el-switch
                v-model="screenshot"
                active-color="#0077FF"
                inactive-color="#999999"
              >
              </el-switch
            ></span>
          </div>
          <div class="item" v-if="screenshot">
            <span>回传频率</span>
            <span
              ><el-select class="frequency" v-model="frequency" size="mini">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option> </el-select
            ></span>
          </div>
        </div>
        <div class="btns flex-hc">
          <el-button size="small" @click="handleClose">取消</el-button>
          <el-button type="primary" size="small" @click="save">保存</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { setCameraSnapshot } from '@/api/index'
export default {
  name: "OpenWebSetCamera",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rowInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      direction: "rtl",
      screenshot: false,
      frequency: "600",
      options: [
        {
          value: "600",
          label: "10分钟（默认）",
        },
        {
          value: "300",
          label: "5分钟",
        },
        {
          value: "120",
          label: "2分钟",
        },
      ],
    };
  },
  watch: {
    rowInfo: {
      handler(newV, oldV) {
        if (newV) {
            // console.log(123)
        }
      },
      deep: true
    },
  },
  mounted() {},

  methods: {
    save() {
      let param = {
        sn: this.rowInfo.sn,
        freq: "0"
      }
      if(this.screenshot){
        param.freq = this.frequency
      }
      setCameraSnapshot(param).then(res=>{
        if(res.code == 200){
          this.$message.success("设置截图回传频率成功")
          this.handleClose()
        }
      })
      // console.log(param, "提交")
    },
    handleClose(done) {
      this.$emit("update:show", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px 20px;
  .form {
    font-size: 14px;
    .item {
      display: flex;
      align-content: center;
      justify-content: space-between;
      border-top: 1px solid #eeeeee;
      border-bottom: 1px solid #eeeeee;
      padding: 10px;
      box-sizing: border-box;
      ::v-deep .el-input__inner {
        border: none;
        box-shadow: none;
      }
    }
    .item:nth-child(2) {
      border-top: none;
      padding-bottom: 5px;
    }
  }
}
</style>