<template>
  <div class="container">
    <div class="header_search">
      <div class="search">
        <el-input
          size="small"
          placeholder="项目名称/注册号码"
          v-model="searchKey"
          class="input-with-select"
        >
          <el-button
            size="small"
            slot="append"
            icon="el-icon-search"
            style="background-color: #5a9cf8; color: #fff"
            @click="getList"
          ></el-button>
        </el-input>
      </div>
      <div class="addBtn">
        <el-select size="small" v-model="status" @change="selectChange">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option
        ></el-select>
      </div>
    </div>
    <div class="table_container">
      <el-table ref="table" height="100%" :data="tableData">
          <!-- prop="projects" -->
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="name"
          label="项目名称"
          width="280"
        >
        <template slot-scope="scope">
           <!-- {{ scope.row.projects ?  scope.row.projects : "-" }} -->
           {{ scope.row.projects ?  scope.row.name : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="phoneNum"
          label="注册号码"
          width="180"
        >
        </el-table-column>
        <el-table-column align="center" prop="createdAt" label="申请时间">
        </el-table-column>
        <el-table-column align="center" prop="status" label="当前状态">
          <template slot-scope="scope">
            <span
              :style="{ color: scope.row.status == 0 ? '#EA6F00' : scope.row.status == 1 ? '#EA0000' : '#70B500' }"
            >
              {{ scope.row.status == 0 ? "待审核" : scope.row.status == 1 ? "已冻结" : "使用中" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 0"
              type="primary"
              @click="accountOption(0, scope.row)"
              size="mini"
            >
              通过
            </el-button>
            <el-button
              v-else-if="scope.row.status == 2"
              type="danger"
              @click="accountOption(2, scope.row)"
              size="mini"
            >
              冻结
            </el-button>
            <el-button
              v-else
              type="success"
              @click="accountOption(1, scope.row)"
              size="mini"
            >
              解冻
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div style="width: 100%;background: #ffffff;padding: 10px;display: flex;justify-content: flex-end">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="400"
        >
        </el-pagination>
      </div> -->
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <AuditAccount :show.sync="show" :content="content" :type="type" :rowInfo="rowInfo" @refush="handleSizeChange"></AuditAccount>
  </div>
</template>

<script>
import AuditAccount from "./components/auditAccount.vue";
import { accountList } from "@/api/index";
export default {
  name: "OpenWebPermissionManage",
  components: {
    AuditAccount,
  },
  data() {
    return {
      rowInfo: {},
      content: "",
      type: 0,
      show: false,
      options: [
        {
          value: "-1",
          label: "全部",
        },
        {
          value: "0",
          label: "待审核",
        },
        {
          value: "2",
          label: "使用中",
        },
        {
          value: "1",
          label: "已冻结",
        },
      ],
      status: "-1", // 状态
      searchKey: "",
      page: 1,
      pageSize: 15,
      total: 0,
      form: {
        sn: "",
        name: "",
      },
      tableData: [
        {
          date: "2016-05-03",
          name: "最后一个name",
          status: 0,
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
    };
  },
  mounted() {
    this.getList()
  },
  activated(){
    this.getList()
  },
  methods: {
    selectChange(){
      this.page = 1
      this.getList()
    },
    // 账号审核 0 通过 1 冻结 2 解冻
    accountOption(type, row) {
      this.rowInfo = row
      let content = "";
      switch (type) {
        case 0:
          content = "是否确认通过审核？";
          this.type = type;
          break;
        case 2:
          content = "是否确认冻结此账号？";
          this.type = type;
          break;
        default:
          content = "是否确认解冻此账号？";
          this.type = type;
          break;
      }
      this.content = content
      this.show = true
    },
    handleSizeChange(e) {
      this.page = 1;
      this.pageSize = e
      this.getList();
    },
    handleCurrentChange(e) {
      this.page = e
      this.getList();
    },
    async getList() {
      let param = {
        phoneNum: this.searchKey,
        status: this.status,
        limit: this.pageSize,
        page: this.page,
      }
      let res = await accountList(param)
      this.total = res.data.total
      res.data.list = res.data.list.map(item=>{
        let name = ""
        if(item.projects){
          item.projects.forEach(it => {
            name += it.name + "、"
          });
        }
        return {
          ...item,
          name: name.slice(0, -1)
        }
      })
      this.tableData = res.data.list
      // console.log(this.tableData, "获取列表数据");
    },
    // search() {
    //   console.log("搜索", this.searchKey);
    // },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background-color: #ecf0f3;
  padding: 10px;
  .header_search {
    display: flex;
    align-items: center;
    height: 72px;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    .search {
      width: 252px;
    }
    .addBtn {
      margin-left: 60px;
      min-width: 32px;
    }
  }
  .table_container {
    width: 100%;
    // height: calc(100% - 72px - 10px);
    height: calc(100% - 72px - 10px - 40px);
    margin-top: 10px;
    background-color: #ffffff;
  }
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep {
  .el-dialog--center .el-dialog__body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }
  .el-table th.el-table__cell {
    background-color: #f4f7fa;
  }
  // .el-dialog--center .el-dialog__body {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   min-height: 200px;
  // }
  // .el-table th.el-table__cell {
  //   background-color: #f4f7fa;
  // }
  // .el-dialog__header {
  //   border-bottom: 1px solid #eeeeee;
  // }
  // .el-dialog__footer {
  //   border-top: 1px solid #eeeeee;
  // }
}
</style>