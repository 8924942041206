<template>
  <custom-dialog
    :show="show"
    title="提示"
    @toggleShow="handleClose"
    width="444"
  >
    <div class="flex form" slot="main">
      {{ content }}
    </div>

    <div class="flex btns" slot="footer">
      <el-button type="primary" class="btn save" size="small" @click="sure">
        <!-- {{ $t("button.submit") }} -->
        确定
      </el-button>
      <el-button
        type="success"
        class="btn cancel"
        size="small"
        @click="handleClose"
      >
        取消
      </el-button>
    </div>
  </custom-dialog>
</template>
  
  <script>
  import { operatAccount } from '@/api/index'
import CustomDialog from "../../../components/public/customDialog";
export default {
  name: "AddDevice",
  components: {
    CustomDialog,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: "",
    },
    type: {
      type: Number,
      default: 0,
    },
    rowInfo: {
      type: Object,
      default: ()=>{
        return {}
      },
    }
  },
  data() {
    return {};
  },
  computed: {
    lang() {
      return this.$store.getters.lang;
    },
  },
  watch: {
    show: {
      handler(newV, oldV) {
        if (newV) {
          console.log(this.rowInfo);
        }
      },
    },
  },
  mounted() {},
  methods: {
    sure() {
      let param = {
        userId: this.rowInfo.userId,
        status: 1,
      }
      if (this.type == 0) {
        // 1 待审核->使用中
        param.status = 2
        // console.log("通过审核");
      } else if (this.type == 1) {
        // 2 已冻结->使用中
        param.status = 2
        // console.log("冻结账号");
      } else {
        // 1 使用中->已冻结
        param.status = 1
        // console.log("解冻账号");
      }
      console.log(param, "param")
      operatAccount(param).then(res=>{
        if(res.code == 200){
          this.$emit("update:show", false);
          this.$emit("refush")
          this.$message.success("账号操作成功")
        }
      })
    },
    handleClose(done) {
      //   this.$refs["form"].resetFields();
      //   this.$emit("toggleShow", null);
      this.$emit("update:show", false);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.btns {
  justify-content: center;

  .btn {
    width: 180px;
    height: 32px;
  }

  .save {
    background-color: #0077ff;
    border-color: #0077ff;
  }

  .cancel {
    border-color: #0077ff;
    background-color: #ffffff;
    color: #0077ff;
    margin-left: 30px;
  }
}

.form {
  // justify-content: space-between;
  justify-content: center;
}

::v-deep {
  .el-dialog__body {
    text-align: center;
  }
}
</style>