<template>
  <div>
    <div class="title">状态码</div>
    <div class="table">
      <el-table :data="tableData" :header-cell-style="{
        backgroundColor: '#0077FF',
        color: '#FFF',
        fontWeight: '500'
      }">
        <el-table-column prop="code" label="状态码" width="400" >
        </el-table-column>
        <el-table-column prop="tips" label="提示" >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "OpenWebCode",

  data() {
    return {
      tableData: [
        {
          code: "10601",
          tips: "appkey不存在",
        },
        {
          code: "10606",
          tips: "设备不存在",
        },
        {
          code: "10607",
          tips: "通道号不存在",
        },
        {
          code: "10609",
          tips: "账号审核中，请等待",
        },
        {
          code: "10610",
          tips: "账号已冻结",
        },
        {
          code: "10614",
          tips: "密钥不正确或已失效",
        },
        {
          code: "401",
          tips: "请求需要验证，token错误或不合法",
        },
        {
          code: "400",
          tips: "其他错误，详情见接口返回msg",
        },
      ],
    };
  },

  methods: {},
};
</script>
<style lang="scss" scoped>
.title {
  padding: 10px 0;
  font-size: 24px;
  font-weight: 500;
}
.table{
  width: 75%;
}
</style>