<template>
  <div class="main">
    <!-- -->
    <div class="content">
      <div class="info" v-for="item in infoList" :key="item.key">
        <div style="margin-right: 50px;">{{ item.label }}</div>
        <div style="text-align: right;">{{ item.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/api/index'
export default {
  name: "OpenWebBaseInfo",

  data() {
    return {
      infoList: [
        {
          label: "项目名称",
          value: "-",
          key: 1,
        },
        {
          label: "手机号码",
          value: "-",
          key: 2,
        },
        {
          label: "注册时间",
          value: "-",
          key: 3,
        },
      ],
    };
  },

  mounted() {
    this.getInfo()
  },

  methods: {
    getInfo(){
      getUserInfo().then(res=>{
        const data = res.data
        let name = ""
        data.projectName?.map(f=>{
          name += f.name + "、"
        })
        this.infoList[0].value = name.slice(0, -1);
        this.infoList[1].value = data.phoneNum
        this.infoList[2].value = data.createdAt
        // console.log(res, "resss")
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  height: 100%;
  padding: 0 10px;
  // width: 100%;
  background-color: #ecf0f3;
  .content {
    height: calc(100% - 20px);
    background-color: #fff;
    padding: 10px;
    .info {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      // width: 300px;
      margin-bottom: 20px;
      font-size: 15px;
    }

    // align-items: center;
    // justify-content: center;
  }
}
</style>