<template>
  <div class="container">
    <div class="title">生成accessToken</div>
    <div class="content main" v-for="(item,index) in info" :key="item.title">
      <template v-if="!item.tableData">
        <div class="listTitle">{{ item.title }}</div>
        <div :style="{color: index == 1 ? '#0077FF' : ''}">{{ item.content }}</div>
      </template>
      <template v-else>
        <div class="listTitle">
          {{ item.title }}
        </div>
        <div class="table">
          <el-table
            :data="item.tableData"
            :header-cell-style="{
              backgroundColor: '#0077FF',
              color: '#FFF',
              fontWeight: '500',
            }"
          >
            <el-table-column
              v-for="(column, index) in item.tableKey"
              :key="column"
              :prop="column"
              :label="item.tableName[index]"
            >
            </el-table-column>
            <!-- <el-table-column prop="tips" label="提示"> </el-table-column> -->
          </el-table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "OpenWebToken",

  data() {
    return {
      info: [
        {
          title: "1、接口说明",
          content: "accessToken是开发者调用接口的认证，开发可以者使用账appkey和secret生成。",
        },
        {
          title: "2、接口地址",
          content: "https://{域名}/api/v1/dev/getAccessToken",
        },
        {
          title: "3、请求类型",
          content: "post",
        },
        {
          title: "4、请求参数",
          content: "",
          tableData: [
            {
              name: "appkey",
              type: "string",
              require: "Y",
              example: "",
              describe: "开发标识码",
            },
            {
              name: "appSecret",
              type: "string",
              require: "Y",
              example: "",
              describe: "开发密钥",
            }
          ],
          tableKey: ["name", "type", "require", "example", "describe"],
          tableName: ["名称", "类型", "是否必填", "示例值", "描述"],
        },
        {
          title: "5、响应参数",
          content: "",
          tableData: [{
              name: "code",
              type: "string",
              require: "Y",
              example: "",
              describe: "返回状态码",
            },
            {
              name: "msg",
              type: "string",
              require: "Y",
              example: "成功",
              describe: "返回状态消息",
            },
            {
              name: "data",
              type: "object",
              require: "",
              example: "",
              describe: "",
            }],
            tableKey: ["name", "type", "require", "example", "describe"],
            tableName: ["名称", "类型", "是否必填", "示例值", "描述"],
        },
        {
          title: "6、data",
          content: "",
          tableData: [{
              name: "token",
              type: "string",
              require: "Y",
              example: "",
              describe: "开发token",
            },
            // {
            //   name: "expire",
            //   type: "string",
            //   require: "Y",
            //   example: "",
            //   describe: "token失效时间",
            // }
          ],
            tableKey: ["name", "type", "require", "example", "describe"],
            tableName: ["名称", "类型", "是否必填", "示例值", "描述"],
        }
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
.title {
  padding: 10px 0;
  font-size: 24px;
  font-weight: 500;
}
.listTitle{
  font-weight: 500;
}
.main > div {
  margin: 20px 0;
  // background-color: red;
}
.table {
  width: 75%;
}
.main:last-child{
  padding-bottom: 100px;
}
</style>