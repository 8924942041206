<template>
  <custom-dialog
    :show="show"
    title="直播地址"
    @toggleShow="handleClose"
    width="550"
  >
    <div class="form" slot="main">
      <div class="content">
        <div class="title">FLV播放地址</div>
        <div class="address">
          <!-- https://{域名}/api/v1/dev/getAccessTokenhttps://{域名}/api/v1/dev/getAccessTokenhttps://{域名}/api/v1/dev/getAccessTokenhttps://{域名}/api/v1/dev/getAccessToken -->
          {{ flvaddress }}
        </div>
        <el-button size="mini" type="primary" @click="copy('flv')"
          >复制</el-button
        >
      </div>
      <div class="content">
        <div class="title">HLS播放地址</div>
        <div class="address">
          <!-- https://{域名}/api/v1/dev/getAccessTokenhttps://{域名}/api/v1/dev/getAccessTokenhttps://{域名}/api/v1/dev/getAccessTokenhttps://{域名}/api/v1/dev/getAccessToken -->
          {{ hlsaddress }}
        </div>
        <el-button size="mini" type="primary" @click="copy('hls')"
          >复制</el-button
        >
      </div>
    </div>
  </custom-dialog>
</template>
    
    <script>
import { getAddress } from "@/api/index";
import CustomDialog from "../../../components/public/customDialog";
export default {
  name: "OpenWebAddress",
  components: {
    CustomDialog,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rowInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      flvaddress: "-",
      hlsaddress: "-",
    };
  },
  computed: {
    lang() {
      return this.$store.getters.lang;
    },
  },
  watch: {
    show: {
      handler(newV, oldV) {
        if (newV) {
          //   console.log(213);
        }
      },
    },
    rowInfo: {
      handler(newV, oldV) {
        if (newV.sn && this.type == 'address') {
          // console.log(123);
          this.toGetAddress();
        }
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    copy(type) {
      let copyValue = "";
      if (type == "flv") {
        copyValue = this.flvaddress;
      } else {
        copyValue = this.hlsaddress;
      }
      this.$message.success("复制成功");
      navigator.clipboard.writeText(copyValue);
    },
    toGetAddress() {
      let param = {
        sn: this.rowInfo.sn,
        channel: this.rowInfo.channel,
      };
      let formData = new FormData();
      formData.append("sn", this.rowInfo.sn);
      formData.append("channel", this.rowInfo.channel);
      getAddress(formData).then((res) => {
        // console.log(res, "直播地址")
        if (res.data) {
          this.flvaddress = res.data.addressList[0].address;
          this.hlsaddress = res.data.addressList[1].address;
        }
      });
    },
    handleClose(done) {
      this.$emit("update:show", false);
      this.$emit("update:rowInfo", {});
    },
  },
};
</script>
    
<style lang="scss" scoped>
.btns {
  justify-content: flex-end;

  .btn {
    width: 180px;
    height: 32px;
  }

  .save {
    background-color: #0077ff;
    border-color: #0077ff;
  }

  .cancel {
    border-color: #0077ff;
    background-color: #ffffff;
    color: #0077ff;
    margin-left: 30px;
  }
}

.form {
  // justify-content: space-between;
  justify-content: center;
  .content {
    // width: 250px;
    display: flex;
    align-items: center;
    /* 去掉滚动条 */

    .title {
      //   width: 250px;
      white-space: nowrap;
      //   margin-right: 5px;
    }
    .address {
      width: 100%;
      border: 1px solid #eeeeee;
      margin: 0 10px;
      overflow: auto;
      -ms-overflow-style: none; /* IE 10+ */
      overflow: -moz-scrollbars-none; /* Firefox */
      white-space: nowrap;
    }
    // 谷歌浏览器
    .address::-webkit-scrollbar {
      display: none;
    }
  }
  .address {
    text-align: left;
    border: 1px solid #eeeeee;
    padding: 10px;
  }
}

::v-deep {
  .el-dialog__body {
    text-align: center;
  }
}
</style>